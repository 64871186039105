import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { Coordinates} from '@parashift/shared/models';
import { Document } from '@parashift/shared/models';

export interface PageAndCoordinates {
  coordinates: Coordinates;
  page_number: number;
}

@Injectable({
  providedIn: 'root',
})
export class DocumentTypeHelperService {

  private currentDocumentTypeId: string;

  private _setCoordinates = new Subject<PageAndCoordinates>();
  setCoordinates$ = this._setCoordinates.asObservable();

  private _setSubtitle = new Subject<string>();
  setSubtitle$ = this._setSubtitle.asObservable();

  private _setNextDocument = new Subject<Document>();
  setNextDocument$ = this._setNextDocument.asObservable();

  private _setPreviousDocument = new Subject<Document>();
  setPreviousDocument$ = this._setPreviousDocument.asObservable();

  disableForm = false;
  preloadedDocuments: Document[] = [];
  preloadedDocumentIds: string[] = [];

  currentDocument: Document;

  private _currentDocument = new Subject<Document>();
  currentDocument$ = this._currentDocument.asObservable();
  documentIsShown = false;

  checkCurrentDocumentType(id: string) {
    if (!this.currentDocumentTypeId || id !== this.currentDocumentTypeId) {
      this.currentDocumentTypeId = id;
      this.preloadedDocuments = [];
      this.preloadedDocumentIds = [];
      this.currentDocument = undefined;
      this._currentDocument.next(this.currentDocument);
      this.documentIsShown = false;
    }
  }

  setCoordinates(coordinates: Coordinates | undefined, page_number: number) {
    let roundedCoordinates = null;

    if (coordinates) {
      const decimalPlaces = 2;
      // + 2 as our coords from BE come with values > 1, while the user works with percent values from 0-100 => retains precision
      const decimalsForPercent = decimalPlaces + 2;

      roundedCoordinates = {
        top: parseFloat(coordinates.top.toFixed(decimalsForPercent)),
        right: parseFloat(coordinates.right.toFixed(decimalsForPercent)),
        bottom: parseFloat(coordinates.bottom.toFixed(decimalsForPercent)),
        left: parseFloat(coordinates.left.toFixed(decimalsForPercent)),
      };
    }

    this._setCoordinates.next({ coordinates: (roundedCoordinates as Coordinates) || coordinates, page_number });
  }

  setSubtitle(subtitle: string) {
    this._setSubtitle.next(subtitle);
  }

  setNextDocument() {
    if (this.preloadedDocuments.length < 2) {
      return;
    }

    const currentIndex = this.preloadedDocuments.findIndex(document => document.id === this.currentDocument.id);

    if (currentIndex > -1 && this.preloadedDocuments[currentIndex + 1]) {
      this.currentDocument = this.preloadedDocuments[currentIndex + 1];
    } else {
      this.currentDocument = this.preloadedDocuments[0];
    }

    this._currentDocument.next(this.currentDocument);
    this._setNextDocument.next(this.currentDocument);
  }

  setPreviousDocument() {
    if (this.preloadedDocuments.length < 2) {
      return;
    }

    const currentIndex = this.preloadedDocuments.findIndex(document => document.id === this.currentDocument.id);

    if (currentIndex > 0) {
      this.currentDocument = this.preloadedDocuments[currentIndex - 1];
    } else {
      this.currentDocument = this.preloadedDocuments[this.preloadedDocuments.length - 1];
    }

    this._currentDocument.next(this.currentDocument);
    this._setNextDocument.next(this.currentDocument);
  }

  storePreloadedDocuments(documents: Document[]) {
    this.preloadedDocuments = [];
    this.preloadedDocumentIds = [];
    this.preloadedDocuments = documents;
    documents.forEach(document => this.preloadedDocumentIds.push(document.id));
    this.currentDocument = this.preloadedDocuments[0];
    this._currentDocument.next(this.currentDocument);
  }

  storePreloadedDocumentIds(documents: Document[]) {
    this.preloadedDocumentIds = [];
    documents.forEach(document => this.preloadedDocumentIds.push(document.id));
  }

  setCurrentDocumentById(id: string) {
    if (this.preloadedDocuments.length === 0) {
      this.currentDocument = undefined;
      this._currentDocument.next(this.currentDocument);
      return;
    }

    const index = this.preloadedDocuments.findIndex(document => document.id === id);

    if (index > -1) {
      this.currentDocument = this.preloadedDocuments[index];
    } else {
      this.currentDocument = this.preloadedDocuments[0];
    }

    this._currentDocument.next(this.currentDocument);
  }
}
